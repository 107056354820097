import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import { FullWidthSanityImage } from "../../vendor/hoa/sanity_image"

const Styled = styled.div`
  margin: 0 auto;
  max-width: 750px;
`

const NutritionLabel = ({ image }) => (
  <Styled>
    <FullWidthSanityImage image={image} renderLoadingBackground={false} />
  </Styled>
)

NutritionLabel.propTypes = {
  image: PropTypes.object.isRequired,
}

export default NutritionLabel

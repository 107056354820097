import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import Container from "../Container"

const Styled = styled.div`
  margin-bottom: 60px;

  @media (max-width: ${props => props.theme.phoneMax}) {
    margin-top: 20px;
  }

  @media (min-width: ${props => props.theme.tabletMin}) {
    column-gap: 25px;
    display: grid;
    grid-template-areas:
      "images content"
      "images .";
    grid-template-columns: 1fr clamp(350px, 40vw, 500px);
    grid-template-rows: auto 1fr;
  }
`

const ProductPageLayout = ({ children }) => (
  <Container>
    <Styled>{children}</Styled>
  </Container>
)

ProductPageLayout.propTypes = {
  children: PropTypes.node,
}

ProductPageLayout.Content = styled.div`
  grid-area: content;

  @media (min-width: ${props => props.theme.tabletMin}) {
    padding-right: 20px;
    position: sticky;
    top: 0px;
  }
`

ProductPageLayout.Images = styled.div`
  grid-area: images;
`

ProductPageLayout.PhoneTitle = styled.div`
  margin-bottom: 20px;

  @media (min-width: ${props => props.theme.tabletMin}) {
    display: none;
  }
`

ProductPageLayout.TabletTitle = styled.div`
  margin: 20px 0;

  @media (max-width: ${props => props.theme.phoneMax}) {
    display: none;
  }
`

export default ProductPageLayout

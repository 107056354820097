import PropTypes from "prop-types"
import React from "react"

const ScrollToLink = ({ children, href }) => (
  <a
    href={href}
    onClick={event => {
      const target = document.querySelector(href)

      if (target) {
        event.preventDefault()
        target.scrollIntoView({ behavior: "smooth" })
      }
    }}
  >
    {children}
  </a>
)

ScrollToLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string.isRequired,
}

export default ScrollToLink

import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import { PHONE_MAX } from "../CSSVariables"
import Carousel from "../Carousel"
import { SanityImage } from "../../vendor/hoa/sanity_image"

const StyledCarousel = styled(Carousel)`
  @media (min-width: ${props => props.theme.tabletMin}) {
    display: grid;
    gap: var(--grid-gap);
    grid-template-columns: repeat(2, 1fr);
  }
`

const ImageContainer = styled.div`
  @media (min-width: ${props => props.theme.tabletMin}) {
    grid-column: ${({ desktopLayout }) =>
      desktopLayout === "fullWidth" ? "1 / -1" : "auto"};
  }
`

const StyledImage = styled(SanityImage)`
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
`

const PDPImages = ({ images }) => (
  <StyledCarousel maxWindowWidth={PHONE_MAX}>
    {images.map(image => (
      <ImageContainer
        key={image.image._id}
        desktopLayout={images.length === 1 ? "fullWidth" : image.desktopLayout}
      >
        <StyledImage image={image.image} loading="eager" />
      </ImageContainer>
    ))}
  </StyledCarousel>
)

PDPImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default PDPImages

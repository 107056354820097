import React, { useContext } from "react"
import styled from "@emotion/styled"
import internalLinkPath from "../../lib/internalLinkPath"
import CMSContext from "../context/CMSContext"

const Styled = styled.div`
  margin-top: 30px;
  text-align: center;

  a {
    text-decoration: underline;
  }
`

const Prop65Link = () => {
  const {
    siteSettings: { prop65Message, prop65Page },
  } = useContext(CMSContext)

  return (
    <Styled>
      <a href={internalLinkPath(prop65Page)} target="_blank" rel="noreferrer">
        {prop65Message}
      </a>
    </Styled>
  )
}

Prop65Link.propTypes = {}

export default Prop65Link

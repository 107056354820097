import { isEmpty } from "lodash"

export const getURLParam = param =>
  new URL(window.location.href).searchParams.get(param)

export const toQueryString = params => {
  const searchParams = new URLSearchParams()

  Object.entries(params).forEach(([key, value]) => {
    searchParams.append(key, value)
  })

  return searchParams.toString()
}

export const currentPathWithNewParams = params => {
  const query = isEmpty(params) ? "" : `?${toQueryString(params)}`
  return `${window.location.pathname}${query}`
}

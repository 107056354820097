import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import { PHONE_MAX } from "../CSSVariables"
import Container from "../Container"
import { StyledRichText } from "../../vendor/hoa/rich_text"
import { FullWidthSanityImage } from "../../vendor/hoa/sanity_image"
import { Title } from "../Headings"

const Styled = styled.div`
  column-gap: 40px;
  display: grid;
  margin: var(--default-margin) 0;
  row-gap: 25px;

  @media (max-width: ${props => props.theme.phoneMax}) {
    padding: 20px;
  }

  @media (min-width: ${props => props.theme.tabletMin}) {
    align-items: center;
    grid-auto-flow: dense;
    grid-template-areas: "content image";
    grid-template-columns: 1fr 1fr;
    padding: 0 40px;
  }
`

const ContentContainer = styled.div`
  @media (min-width: ${props => props.theme.tabletMin}) {
    grid-area: content;
  }
`

const ImageContainer = styled.div`
  @media (min-width: ${props => props.theme.tabletMin}) {
    grid-area: image;
  }
`

const StyledTitle = styled(Title)`
  margin-bottom: 20px;
`

const WhatsInside = ({ content, image, title }) => (
  <Container>
    <Styled>
      <ImageContainer>
        <FullWidthSanityImage
          image={image}
          sizes={`(max-width: ${PHONE_MAX}px) 100vw, 50vw`}
        />
      </ImageContainer>

      <ContentContainer>
        <StyledTitle>{title}</StyledTitle>
        <StyledRichText blocks={content} />
      </ContentContainer>
    </Styled>
  </Container>
)

WhatsInside.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
}

export default WhatsInside

import { debounce } from "lodash"
import { useEffect, useState } from "react"

const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState({})

  useEffect(() => {
    const resize = () => {
      setDimensions({ height: window.innerHeight, width: window.innerWidth })
    }

    resize()

    const debouncedResize = debounce(resize, 500)
    window.addEventListener("resize", debouncedResize, true)

    return () => {
      window.removeEventListener("resize", debouncedResize, true)
    }
  }, [])

  return dimensions
}

export default useWindowDimensions

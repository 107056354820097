import { observer } from "mobx-react-lite"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import Button from "../Button"
import useProductWithInventoryAndSelection from "../../hooks/useProductWithInventoryAndSelection"
import CMSContext from "../context/CMSContext"
import StoreContext from "../context/StoreContext"
import ProductPageLayout from "./ProductPageLayout"
import SubscriptionTypeSelector from "./SubscriptionTypeSelector"
import VariantSelectors from "./VariantSelectors"
import styled from "@emotion/styled"
import BackInStockButton from "./BackInStockButton"
import {
  filterOnSelection,
  selectedVariant,
} from "../../lib/shopify/productUtil"
import { currentPathWithNewParams, getURLParam } from "../../lib/browserUtil"
import PDPImages from "./PDPImages"
import Guarantee from "./Guarantee"
import ProductDescription from "./ProductDescription"
import ProductTitleAndPrice from "./ProductTitleAndPrice"
import SupplementPdpContent from "./SupplementPdpContent"
import { isPreOrder } from "../../lib/shopify/productTags"
import RelatedProducts from "./RelatedProducts"
import Prop65Link from "./Prop65Link"
import SupplementAnchorLinks from "./SupplementAnchorLinks"
import { trackProductDetailView } from "../../lib/tracking/tracking"
import ShopPayInstallments from "./ShopPayInstallments"
import formatCurrency from "../../lib/formatCurrency"
import Badge from "./Badge"
import PDPVideo from "./PDPVideo"
import PageComponents from "../PageComponents"

const PreOrderText = styled.div`
  margin: 0 0 8px 0;
`

const ButtonContainer = styled.div`
  margin-top: 20px;
`

const outOfStockMessage = "Out of Stock"

const getBadge = product => {
  switch (product.customInventoryType) {
    case "outOfStock":
      return outOfStockMessage

    case "preLaunch":
      return "Available Soon"
  }

  if (product.variants.every(v => !v.availableForSale)) {
    return outOfStockMessage
  }
}

const ProductPage = ({ product: productWithoutInventory }) => {
  const {
    siteSettings: { onlyLeftThreshold, preOrderText },
  } = useContext(CMSContext)

  const { checkoutStore, productStore, overlayStore } = useContext(StoreContext)

  const { isInventoryLoaded, product, selection, setSelection } =
    useProductWithInventoryAndSelection(productWithoutInventory)

  const variant = selectedVariant(product, selection)

  useEffect(() => {
    if (isInventoryLoaded) {
      trackProductDetailView({ product, variant })
    }
  }, [isInventoryLoaded])

  useEffect(() => {
    if (
      isInventoryLoaded &&
      product.variants.length > 1 &&
      getURLParam("variant") !== variant.legacyResourceId
    ) {
      const newPath = currentPathWithNewParams({
        variant: variant.legacyResourceId,
      })

      history.replaceState({}, "", newPath)
    }
  }, [isInventoryLoaded, variant.legacyResourceId])

  const [subscriptionType, setSubscriptionType] = useState(
    product.rechargeProduct ? "one-time" : null
  )

  const selectOption = ({ name, value }) => {
    setSelection(prev => ({ ...prev, [name]: value }))
  }

  const onlyLeftCount =
    onlyLeftThreshold &&
    variant.quantityAvailable &&
    variant.quantityAvailable <= onlyLeftThreshold
      ? variant.quantityAvailable
      : null

  const addToCart = () => {
    checkoutStore.addToCart(variant.id, subscriptionType)
    overlayStore.showCart()
  }

  const price = productStore.getPrice(
    variant.price,
    product.legacyResourceId,
    subscriptionType
  )

  const isSupplement = product.pdpContent?.[0]?._type === "supplementPdp"

  return (
    <div className="pt-[140px]">
      <ProductPageLayout>
        <ProductPageLayout.PhoneTitle>
          <ProductTitleAndPrice
            isH1
            product={product}
            subscriptionType={subscriptionType}
            variant={variant}
          />
        </ProductPageLayout.PhoneTitle>

        <ProductPageLayout.Images>
          <PDPImages
            images={filterOnSelection(product.pdpImages || [], selection)}
          />
        </ProductPageLayout.Images>

        <ProductPageLayout.Content>
          <ProductPageLayout.TabletTitle>
            <ProductTitleAndPrice
              product={product}
              subscriptionType={subscriptionType}
              variant={variant}
            />
          </ProductPageLayout.TabletTitle>

          <ProductDescription product={product} selection={selection} />

          {getBadge(product) && (
            <div className="flex mt-3">
              <Badge>{getBadge(product)}</Badge>
            </div>
          )}

          {isSupplement && <SupplementAnchorLinks product={product} />}

          {product.variants.length > 1 && (
            <VariantSelectors
              isReadyForSelection={isInventoryLoaded}
              product={product}
              selection={selection}
              onlyLeftCount={onlyLeftCount}
              onSelect={selectOption}
            />
          )}

          {subscriptionType && (
            <SubscriptionTypeSelector
              onSetSubscriptionType={setSubscriptionType}
              product={product}
              subscriptionType={subscriptionType}
            />
          )}

          <ShopPayInstallments variant={variant} />

          {product.customInventoryType === "preLaunch" && (
            <div>
              Register your email address below to receive an email and to be
              the first to know as soon as products drop.
            </div>
          )}

          <ButtonContainer>
            {isPreOrder(product) && preOrderText && (
              <PreOrderText>{preOrderText}</PreOrderText>
            )}

            {variant.availableForSale ? (
              <Button onClick={addToCart}>
                {isPreOrder(product)
                  ? "Pre-order"
                  : subscriptionType === "subscription"
                  ? "Subscribe"
                  : "Add To Cart"}{" "}
                | {formatCurrency(price)}
              </Button>
            ) : (
              <BackInStockButton product={product} variant={variant} />
            )}

            <Guarantee />
          </ButtonContainer>

          {product.video && <PDPVideo video={product.video} />}
        </ProductPageLayout.Content>
      </ProductPageLayout>

      {isSupplement && <SupplementPdpContent content={product.pdpContent[0]} />}

      {product.bottomComponents?.[0] && (
        <PageComponents components={product.bottomComponents} />
      )}

      {product.relatedProducts && (
        <RelatedProducts filterProduct={product} {...product.relatedProducts} />
      )}

      {product.isProp65 && <Prop65Link />}
    </div>
  )
}

ProductPage.propTypes = {
  product: PropTypes.object.isRequired,
}

export default observer(ProductPage)

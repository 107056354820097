import { groupBy, mapValues } from "lodash"
import { useEffect, useMemo, useState } from "react"
import { getURLParam } from "../lib/browserUtil"
import createShopifyClient from "../lib/shopify/createShopifyClient"
import { defaultVariant, variantSelection } from "../lib/shopify/productUtil"
import * as Sentry from "@sentry/browser"

// Optimistically assume that all variants are in stock. This will be true most of the time and
// will avoid having the UI update shortly after page load.
const updateAssumingAllAvailable = product => ({
  ...product,
  variants: product.variants.map(variant => ({
    ...variant,
    availableForSale: true,
  })),
})

const updateAssumingNoneAvailable = product => ({
  ...product,
  variants: product.variants.map(variant => ({
    ...variant,
    availableForSale: false,
    quantityAvailable: 0,
  })),
})

const updateWithQuantity = (product, respProduct) => {
  if (!respProduct) {
    return product
  }

  const idToVariant = mapValues(
    groupBy(
      respProduct.variants.edges.map(({ node }) => node),
      variant => atob(variant.id)
    ),
    ([variant]) => variant
  )

  return {
    ...product,
    variants: product.variants.map(variant => ({
      ...variant,
      availableForSale: idToVariant[variant.id].availableForSale,
      quantityAvailable: idToVariant[variant.id].quantityAvailable,
    })),
  }
}

const fetchShopifyProduct = async product => {
  const productId = btoa(product.id)
  const client = await createShopifyClient()
  return (await client.product.fetch(productId)).data.node
}

const getUrlVariant = product => {
  const urlVariantId = getURLParam("variant")
  return (
    urlVariantId &&
    product.variants.find(v => v.legacyResourceId === urlVariantId)
  )
}

const useProductWithInventoryAndSelection = productWithoutInventory => {
  const [isInventoryLoaded, setInventoryLoaded] = useState(false)
  const [shopifyProduct, setShopifyProduct] = useState(null)

  // Set initial selection, ignoring URL params so this will work with server-side rendering
  const [selection, setSelection] = useState(() =>
    variantSelection(defaultVariant(productWithoutInventory))
  )

  useEffect(async () => {
    const urlVariant = getUrlVariant(productWithoutInventory)

    // If the variant URL param is present, then override the default selection
    if (urlVariant) {
      setSelection(variantSelection(urlVariant))
    }

    try {
      // fetch product from Shopify so we have up-to-date inventory data
      const fetchedProduct = await fetchShopifyProduct(productWithoutInventory)
      setShopifyProduct(fetchedProduct)

      if (!urlVariant) {
        // Update default selection taking into account product availability
        setSelection(
          variantSelection(
            defaultVariant(
              updateWithQuantity(productWithoutInventory, fetchedProduct)
            )
          )
        )
      }
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
    } finally {
      setInventoryLoaded(true)
    }
  }, [])

  const product = useMemo(() => {
    if (productWithoutInventory.customInventoryType) {
      return updateAssumingNoneAvailable(productWithoutInventory)
    } else if (shopifyProduct) {
      return updateWithQuantity(productWithoutInventory, shopifyProduct)
    } else {
      return updateAssumingAllAvailable(productWithoutInventory)
    }
  }, [productWithoutInventory, shopifyProduct])

  return { isInventoryLoaded, product, selection, setSelection }
}

export default useProductWithInventoryAndSelection

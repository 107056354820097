import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "@emotion/styled"
import { EventLinkButton } from "../Button"
import Container from "../Container"
import NutritionLabel from "./NutritionLabel"

const Styled = styled.div`
  margin-top: 30px;
`

const SelectorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin: 0 20px 20px 20px;
`

const NutritionLabels = ({ nutritionLabels }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const selectedLabel = nutritionLabels[selectedIndex]

  return (
    <Container>
      <Styled id="nutrition-labels">
        {nutritionLabels.length > 1 && (
          <SelectorContainer>
            {nutritionLabels.map((label, index) => (
              <EventLinkButton
                key={index}
                isInline
                onClick={() => {
                  setSelectedIndex(index)
                }}
                theme={selectedLabel === label ? "" : "darkOnLightWithBorder"}
              >
                {label.name}
              </EventLinkButton>
            ))}
          </SelectorContainer>
        )}

        <NutritionLabel {...selectedLabel} />
      </Styled>
    </Container>
  )
}

NutritionLabels.propTypes = {
  nutritionLabels: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default NutritionLabels

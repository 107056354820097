import { clamp } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import styled from "@emotion/styled"
import useWindowDimensions from "../hooks/useWindowDimensions"

const scrollTreshold = 10

const Styled = styled.div`
  @media (max-width: ${({ maxWindowWidth }) => maxWindowWidth}px) {
    overflow-x: hidden;
    width: 100%;
  }
`

const Grid = styled.div`
  @media (max-width: ${({ maxWindowWidth }) => maxWindowWidth}px) {
    --carousel-enabled: true;

    column-gap: var(--grid-gap);
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    transition: transform 0.3s;
  }
`

const getColumnGap = elem => {
  const gapStyle = getComputedStyle(elem).columnGap || ""
  const match = gapStyle.match(/(\d+)px/)

  if (match) {
    return Number(match[1])
  } else {
    console.error("invalid gap", gapStyle)
    return 0
  }
}

const getPointerEventLocation = nativeEvent => ({
  x: nativeEvent.targetTouches[0].clientX,
  y: nativeEvent.targetTouches[0].clientY,
})

const useCarousel = ({ containerRef, gridRef, itemCount }) => {
  const scrollRef = useRef({
    diff: 0,
    mode: null, // "carousel" or "window"
    slideIndex: 0,
    start: null, // like { x: 1, y: 2}
  })

  const isCarouselEnabled = () =>
    !!getComputedStyle(gridRef.current).getPropertyValue("--carousel-enabled")

  const updateScroll = opts => {
    scrollRef.current = { ...scrollRef.current, ...opts }

    if (!isCarouselEnabled()) {
      gridRef.current.style.transform = ""
      gridRef.current.style.transition = ""
      return
    }

    const { diff, slideIndex, start } = scrollRef.current

    const width = gridRef.current.offsetWidth + getColumnGap(gridRef.current)
    const maxOffset = width * (itemCount - 1)
    const offset = clamp(slideIndex * width + diff, 0, maxOffset)

    gridRef.current.style.transform = `translateX(${-offset}px)`
    gridRef.current.style.transition = start ? "none" : ""
  }

  const updateIndex = delta => {
    const { slideIndex } = scrollRef.current
    const newIndex = clamp(slideIndex + delta, 0, itemCount - 1)

    updateScroll({ diff: 0, mode: null, slideIndex: newIndex, start: null })
  }

  const { width } = useWindowDimensions()

  useEffect(() => {
    updateIndex(0)

    // preventDefault doesn't work in React onTouchMove event; using native event handler instead
    containerRef.current.addEventListener(
      "touchmove",
      event => {
        const { mode } = scrollRef.current

        if (isCarouselEnabled() && mode === "carousel") {
          event.preventDefault()
        }
      },
      true
    )
  }, [itemCount, width])

  return { scrollRef, updateIndex, updateScroll }
}

const Carousel = ({ className, children, maxWindowWidth = 999999 }) => {
  const containerRef = useRef(null)
  const gridRef = useRef(null)

  const { scrollRef, updateIndex, updateScroll } = useCarousel({
    containerRef,
    gridRef,
    itemCount: children.length,
  })

  return (
    <Styled
      ref={containerRef}
      maxWindowWidth={maxWindowWidth}
      onTouchStart={event => {
        updateScroll({
          diff: 0,
          start: getPointerEventLocation(event.nativeEvent),
        })
      }}
      onTouchMove={event => {
        const { mode, start } = scrollRef.current
        const current = getPointerEventLocation(event.nativeEvent)

        if (!start) {
          // See https://github.com/house-of-athlete/hoa_gatsby/pull/152
          return
        }

        const absDiff = {
          x: Math.abs(start.x - current.x),
          y: Math.abs(start.y - current.y),
        }

        if (!mode) {
          if (absDiff.x > scrollTreshold && absDiff.x > absDiff.y) {
            updateScroll({ mode: "carousel" })
          } else if (absDiff.y > scrollTreshold) {
            updateScroll({ mode: "window" })
          }
        }

        if (scrollRef.current.mode === "carousel") {
          updateScroll({ diff: start.x - current.x })
        }
      }}
      onTouchEnd={() => {
        const { diff, mode } = scrollRef.current

        if (mode === "carousel" && diff > 0) {
          updateIndex(1)
        } else if (mode === "carousel" && diff < 0) {
          updateIndex(-1)
        } else {
          updateIndex(0)
        }
      }}
    >
      <Grid className={className} maxWindowWidth={maxWindowWidth} ref={gridRef}>
        {children}
      </Grid>
    </Styled>
  )
}

Carousel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  maxWindowWidth: PropTypes.number,
}

export default Carousel

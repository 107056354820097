import PropTypes from "prop-types"
import React, { useContext } from "react"
import { observer } from "mobx-react-lite"
import StoreContext from "../context/StoreContext"
import styled from "@emotion/styled"
import formatCurrency from "../../lib/formatCurrency"

const Styled = styled.div`
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr auto;
  line-height: 1.125;
`
const Title = styled.div`
  font-size: 1.0625rem;
  letter-spacing: -0.02em;
  text-transform: uppercase;
`

const PriceContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-end;
`

const CompareAtPrice = styled.div`
  color: var(--color-disabled);
  font-weight: var(--weight-thin);
  text-decoration: line-through;
`

const Price = styled.div`
  font-size: 1.0625rem;
  font-weight: var(--weight-slight-bold);
`

const ProductTitleAndPrice = ({ isH1, product, subscriptionType, variant }) => {
  const { productStore } = useContext(StoreContext)

  const price = productStore.getPrice(
    variant.price,
    product.legacyResourceId,
    subscriptionType
  )

  return (
    <Styled>
      <Title as={isH1 ? "h1" : "div"}>{product.title}</Title>

      <PriceContainer>
        {variant.compareAtPrice && (
          <CompareAtPrice>
            {formatCurrency(variant.compareAtPrice)}
          </CompareAtPrice>
        )}

        <Price>{formatCurrency(price)}</Price>
      </PriceContainer>
    </Styled>
  )
}

ProductTitleAndPrice.propTypes = {
  isH1: PropTypes.bool,
  product: PropTypes.object.isRequired,
  subscriptionType: PropTypes.string,
  variant: PropTypes.object.isRequired,
}

export default observer(ProductTitleAndPrice)

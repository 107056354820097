import React, { useContext } from "react"
import styled from "@emotion/styled"
import CMSContext from "../context/CMSContext"
import { StyledRichText } from "../../vendor/hoa/rich_text"

const Styled = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0 20px;

  @media (max-width: ${props => props.theme.phoneMax}) {
    font-size: 0.8125rem;
  }
`

const Guarantee = () => {
  const {
    siteSettings: { guarantee },
  } = useContext(CMSContext)

  return (
    <Styled>
      <StyledRichText blocks={guarantee} />
    </Styled>
  )
}

Guarantee.propTypes = {}

export default Guarantee

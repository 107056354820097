import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"

const bgColor = "var(--background-dark)",
  checkColor = "var(--color-brand-secondary)"

const StyledCircle = styled.svg`
  display: block;

  circle {
    transition: fill 0.1s linear, stroke 0.1s linear;
  }
`

const Circle = ({ checked }) => (
  <StyledCircle
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    aria-hidden="true"
    focusable="false"
  >
    {/* background circle */}
    <circle
      cx="14"
      cy="14"
      r="13.5"
      fill={checked ? bgColor : "transparent"}
      stroke={checked ? bgColor : "black"}
    />

    {/* checked circle */}
    <circle cx="14" cy="14" r="6" fill={checked ? checkColor : "transparent"} />
  </StyledCircle>
)

Circle.propTypes = {
  checked: PropTypes.bool.isRequired,
}

const OverlayContainer = styled.div`
  position: relative;
`

const InvisibleInput = styled.input`
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
`

const RadioButton = ({ checked, ...props }) => (
  <OverlayContainer>
    <Circle checked={checked} />
    <InvisibleInput type="radio" checked={checked} {...props} />
  </OverlayContainer>
)

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
}

const Label = styled.label`
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  display: grid;
  font-size: 0.8125rem;
  letter-spacing: 0.03em;
  grid-template-columns: auto 1fr;
  text-transform: uppercase;
`

const RadioWithLabel = ({ children, ...props }) => (
  <Label>
    <RadioButton {...props} />
    {children}
  </Label>
)

RadioWithLabel.propTypes = {
  children: PropTypes.node.isRequired,
}

const RadioGroup = styled.div`
  display: grid;
  margin: 0 0 20px 0;
  row-gap: 8px;
`

export const RadioSelector = ({ onSelect, options, value: selectedValue }) => (
  <RadioGroup>
    {options.map(({ name, value }) => (
      <RadioWithLabel
        checked={value === selectedValue}
        key={name}
        onChange={() => {
          onSelect(value)
        }}
        value={value}
      >
        {name}
      </RadioWithLabel>
    ))}
  </RadioGroup>
)

RadioSelector.propTypes = {
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.string,
}

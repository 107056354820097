import PropTypes from "prop-types"
import React, { useContext } from "react"
import styled from "@emotion/styled"
import shopPayLogo from "../../images/shop-pay-color.svg"
import formatCurrency from "../../lib/formatCurrency"
import { EventLink } from "../../vendor/hoa/links"
import { observer } from "mobx-react-lite"
import StoreContext from "../context/StoreContext"

const priceThreshold = 50

const Styled = styled.div`
  img {
    height: 15px;
    vertical-align: -15%;
  }

  @media (max-width: ${props => props.theme.phoneMax}) {
    img {
      display: block;
    }
  }

  @media (min-width: ${props => props.theme.tabletMin}) {
    a {
      display: block;
    }
  }

  a {
    color: var(--color-medium-gray);
    text-decoration: underline;
  }
`

const ShopPayInstallments = ({ variant }) => {
  const { modalStore } = useContext(StoreContext)

  if (Number(variant.price) < priceThreshold) {
    return null
  }

  return (
    <Styled>
      Pay in full or in 4 interest-free installments of{" "}
      <strong>{formatCurrency(variant.price / 4)}</strong>.{" "}
      <img src={shopPayLogo} alt="Shop Pay Logo" />
      <EventLink
        onClick={() => {
          modalStore.showShopPayInstallmentsModal(variant)
        }}
      >
        Learn More
      </EventLink>
    </Styled>
  )
}

ShopPayInstallments.propTypes = {
  variant: PropTypes.object.isRequired,
}

export default observer(ShopPayInstallments)

import PropTypes from "prop-types"
import React from "react"
import { filterOnSelection } from "../../lib/shopify/productUtil"
import { StyledRichText } from "../../vendor/hoa/rich_text"

const ProductDescription = ({ product, selection }) => {
  const description =
    filterOnSelection(product.descriptions || [], selection)[0]?.content || []

  return (
    <div className="mt-3">
      <StyledRichText blocks={description} />
    </div>
  )
}

ProductDescription.propTypes = {
  product: PropTypes.object.isRequired,
  selection: PropTypes.object.isRequired,
}

export default ProductDescription

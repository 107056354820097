import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import { EventLink } from "../vendor/hoa/links"

const StyledHeading = styled.div`
  align-items: center;
  column-gap: 8px;
  display: grid;
  font-size: 0.6875rem;
  grid-template-columns: repeat(3, auto);
  justify-content: flex-start;
  line-height: 1;
  margin: 32px 0 16px 0;
`

const HeadingName = styled.div`
  font-weight: var(--weight-slight-bold);
  letter-spacing: -0.02em;
  text-transform: uppercase;
`

const HeadingValue = styled.div`
  font-size: 1.09em;
  font-weight: var(--weight-thin-thin);
  position: relative;
  top: -1px;
`

const HeadingNotification = styled.div`
  color: var(--color-brand-secondary);
`

export const SelectorHeading = ({ name, value, notification }) => (
  <StyledHeading>
    <HeadingName>{name}</HeadingName>{" "}
    {value && <HeadingValue>{value}</HeadingValue>}{" "}
    {notification && <HeadingNotification>{notification}</HeadingNotification>}
  </StyledHeading>
)

SelectorHeading.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  notification: PropTypes.string,
}

const StyledButton = styled(EventLink)`
  align-items: center;
  border-radius: 10px;
  border: 1px solid lightgrey;
  display: flex;
  justify-content: center;

  &,
  &:visited,
  &:hover,
  &:active {
    color: var(--selector-color);
  }
`

const DefaultButton = styled(StyledButton)`
  --selector-color: var(--color-medium);
`

const SelectedButton = styled(StyledButton)`
  --selector-color: var(--color-light);

  background-color: var(--color-medium);
  border-color: var(--color-light);
`

const UnavailableButton = styled(StyledButton)`
  --selector-color: lightgrey;

  background: ghostwhite;
  position: relative;

  &::after {
    content: " ";
    position: absolute;
    width: calc(var(--selector-size) * 0.6);
    height: calc(var(--selector-size) * 0.6);
    border-bottom: 1px solid lightgrey;
    transform: rotate(135deg) translateY(calc(var(--selector-size) * -0.3));
  }
`

const UnavailableSelectedButton = styled(UnavailableButton)`
  background: #939393;
`

export const SelectorButton = ({
  color,
  isAvailable,
  isSelected,
  onClick,
  value,
}) => {
  const ButtonComponent = isAvailable
    ? isSelected
      ? SelectedButton
      : DefaultButton
    : isSelected
    ? UnavailableSelectedButton
    : UnavailableButton

  return (
    <ButtonComponent
      onClick={onClick}
      style={color ? { backgroundColor: color } : {}}
    >
      {!color && value}
    </ButtonComponent>
  )
}

SelectorButton.propTypes = {
  color: PropTypes.string,
  isAvailable: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

const StyledRow = styled.div`
  @media (max-width: ${props => props.theme.phoneMax}) {
    --selector-size: 40px;
  }

  @media (min-width: ${props => props.theme.tabletMin}) {
    --selector-size: 50px;
  }

  column-gap: 10px;
  display: grid;
  font-size: 0.6875rem;
  grid-auto-columns: var(--selector-size);
  grid-auto-flow: column;
  grid-template-rows: var(--selector-size);
`

export const SelectorButtonRow = ({ children }) => (
  <StyledRow>{children}</StyledRow>
)

SelectorButtonRow.propTypes = {
  children: PropTypes.node,
}

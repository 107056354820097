import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import { rechargeDiscountDescription } from "../../lib/rechargeUtil"
import { RadioSelector } from "../Radio"

const Styled = styled.div`
  margin: 25px 0;
`

const SubscriptionTypeSelector = ({
  onSetSubscriptionType,
  product,
  subscriptionType,
}) => (
  <Styled>
    <RadioSelector
      onSelect={onSetSubscriptionType}
      options={[
        { name: `One-time purchase`, value: "one-time" },
        {
          name: `Every 30 days (save ${rechargeDiscountDescription(
            product.rechargeProduct
          )})`,
          value: "subscription",
        },
      ]}
      value={subscriptionType}
    />
  </Styled>
)

SubscriptionTypeSelector.propTypes = {
  onSetSubscriptionType: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  subscriptionType: PropTypes.oneOf(["one-time", "subscription"]).isRequired,
}

export default SubscriptionTypeSelector

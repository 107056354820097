import PropTypes from "prop-types"
import React, { useContext } from "react"
import styled from "@emotion/styled"
import {
  optionsWithValues,
  selectedVariant,
} from "../../lib/shopify/productUtil"
import CMSContext from "../context/CMSContext"
import { SelectorHeading, SelectorButton, SelectorButtonRow } from "../Selector"

const Styled = styled.div`
  margin-bottom: 20px;
`

const isColor = name => name?.toLowerCase()?.includes("color")

const VariantSelectors = ({
  isReadyForSelection,
  onlyLeftCount,
  onSelect,
  product,
  selection,
}) => {
  const {
    siteSettings: { colors, optionNames, optionOrder },
  } = useContext(CMSContext)

  const getColor = value =>
    colors.find(color => color.name === value?.toLowerCase())?.color || ""

  const getOptionName = name =>
    optionNames.find(opt => opt.name === name)?.replacementValue || name

  const options = optionsWithValues(product, optionOrder)

  return (
    <Styled>
      {options.map(({ name, values }, index) =>
        values.length > 1 ? (
          <div key={name}>
            <SelectorHeading
              name={getOptionName(name)}
              value={selection[name]}
              notification={
                index === options.length - 1 && onlyLeftCount > 0
                  ? `Only ${onlyLeftCount} left in this ${name}!`
                  : null
              }
            />

            <SelectorButtonRow>
              {values.map(value => (
                <SelectorButton
                  key={value}
                  color={isColor(name) ? getColor(value) : null}
                  isAvailable={
                    index !== options.length - 1 ||
                    selectedVariant(product, { ...selection, [name]: value })
                      .availableForSale
                  }
                  isSelected={isReadyForSelection && selection[name] === value}
                  onClick={() => {
                    onSelect({ name, value })
                  }}
                  value={value}
                />
              ))}
            </SelectorButtonRow>
          </div>
        ) : null
      )}
    </Styled>
  )
}

VariantSelectors.propTypes = {
  isReadyForSelection: PropTypes.bool.isRequired,
  onlyLeftCount: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  selection: PropTypes.object.isRequired,
}

export default VariantSelectors

import PropTypes from "prop-types"
import React, { useContext } from "react"
import { observer } from "mobx-react-lite"
import Button from "../Button"
import StoreContext from "../context/StoreContext"

const BackInStockButton = ({ product, variant }) => {
  const { modalStore } = useContext(StoreContext)

  return (
    <Button
      onClick={() => {
        modalStore.showBackInStockModal({ product, variant })
      }}
    >
      Notify Me When Available
    </Button>
  )
}

BackInStockButton.propTypes = {
  product: PropTypes.object.isRequired,
  variant: PropTypes.object.isRequired,
}

export default observer(BackInStockButton)

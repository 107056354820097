import PropTypes from "prop-types"
import React from "react"
import { CMSLink } from "../../vendor/hoa/links"
import { muxThumbnailURL, PlayButtonOverlay } from "../../vendor/hoa/mux_video"

const PDPVideo = ({ video }) => (
  <CMSLink link={{ _type: "videoModalLink", video }} className="block relative">
    <img src={muxThumbnailURL(video)} />
    <PlayButtonOverlay />
  </CMSLink>
)

PDPVideo.propTypes = {
  video: PropTypes.object.isRequired,
}

export default PDPVideo

import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import Container from "../Container"
import { ResponsiveGrid } from "../../vendor/hoa/responsive_grid"
import CollectionProduct from "./CollectionProduct"
import { Heading } from "../Headings"

const ProductsContainer = styled(ResponsiveGrid)`
  column-gap: var(--grid-gap);
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  row-gap: 35px;
`

const RelatedProducts = ({ filterProduct, heading, products }) => {
  const filteredProducts = products
    .filter(({ id }) => id !== filterProduct?.id)
    .slice(0, 4)

  return (
    <Container>
      <Heading>{heading}</Heading>

      <ProductsContainer columns={2} tabletColumns={4}>
        {filteredProducts.map((product, index) => (
          <CollectionProduct key={index} product={product} />
        ))}
      </ProductsContainer>
    </Container>
  )
}

RelatedProducts.propTypes = {
  filterProduct: PropTypes.object,
  heading: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default RelatedProducts

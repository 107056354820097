import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import Container from "../Container"
import { Title } from "../Headings"
import { StyledRichText } from "../../vendor/hoa/rich_text"
import { SanityImage } from "../../vendor/hoa/sanity_image"

const imageSize = 38

const StyledItem = styled.div`
  align-items: center;
  display: flex;
`

const StyledImage = styled(SanityImage)`
  border-radius: 50%;
  border: 1px solid var(--color-brand-secondary);
  display: block;
  height: ${imageSize}px;
  margin-right: 10px;
  object-fit: cover;
  width: ${imageSize}px;
`

const Item = ({ content, image }) => (
  <StyledItem>
    <StyledImage image={image} sizes={`${imageSize}px`} />
    {content}
  </StyledItem>
)

Item.propTypes = {
  content: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

const Styled = styled.div`
  background: var(--background-light-gray);

  @media (max-width: ${props => props.theme.phoneMax}) {
    padding: 20px;
  }

  @media (min-width: ${props => props.theme.tabletMin}) {
    padding: 40px;
  }
`

const StyledTitle = styled(Title)`
  margin-bottom: 20px;
`

const Layout = styled.div`
  align-items: center;
  column-gap: 60px;
  display: grid;
  row-gap: 20px;

  @media (min-width: ${props => props.theme.tabletMin}) {
    grid-auto-flow: dense;
    grid-template-areas: "content items";
    grid-template-columns: 1fr 1fr;
  }
`

const ItemContainer = styled.div`
  display: grid;
  row-gap: 10px;

  @media (min-width: ${props => props.theme.tabletMin}) {
    grid-area: items;
    grid-template-columns: repeat(2, 1fr);
  }
`

const ContentContainer = styled.div`
  @media (min-width: ${props => props.theme.tabletMin}) {
    grid-area: content;
  }
`

const ProductBenefits = ({ content, items, title }) => (
  <Container>
    <Styled id="product-benefits">
      <StyledTitle>{title}</StyledTitle>

      <Layout>
        <ItemContainer>
          {items.map(item => (
            <Item key={item._key} {...item} />
          ))}
        </ItemContainer>

        <ContentContainer>
          <StyledRichText blocks={content} />
        </ContentContainer>
      </Layout>
    </Styled>
  </Container>
)

ProductBenefits.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
}

export default ProductBenefits

import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import ScrollToLink from "../links/ScrollToLink"

const Styled = styled.div`
  margin: 20px 0 30px;

  > a {
    text-decoration: underline;

    &:not(:first-of-type) {
      border-left: 1px solid var(--color-disabled);
      padding-left: 20px;
    }

    &:not(:last-of-type) {
      padding-right: 20px;
    }
  }

  @media (max-width: ${props => props.theme.phoneMax}) {
    text-align: center;
  }
`

const SupplementAnchorLinks = ({ product }) => {
  const content = product.pdpContent[0]

  return (
    <Styled>
      {content.benefitsContent &&
        content.benefitsItems &&
        content.benefitsTitle && (
          <ScrollToLink href="#product-benefits">See Benefits</ScrollToLink>
        )}

      {content.nutritionLabels?.[0] && (
        <ScrollToLink href="#nutrition-labels">
          View Nutrition Label
        </ScrollToLink>
      )}
    </Styled>
  )
}

SupplementAnchorLinks.propTypes = {
  product: PropTypes.object.isRequired,
}

export default SupplementAnchorLinks

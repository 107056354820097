import PropTypes from "prop-types"
import React from "react"
import NutritionLabels from "./NutritionLabels"
import ProductBenefits from "./ProductBenefits"
import WhatsInside from "./WhatsInside"

const SupplementPdpContent = ({ content }) => (
  <>
    {content.benefitsContent &&
      content.benefitsItems &&
      content.benefitsTitle && (
        <ProductBenefits
          content={content.benefitsContent}
          items={content.benefitsItems}
          title={content.benefitsTitle}
        />
      )}

    {content.insideContent && content.insideImage && content.insideTitle && (
      <WhatsInside
        content={content.insideContent}
        image={content.insideImage}
        title={content.insideTitle}
      />
    )}

    {content.nutritionLabels?.[0] && (
      <NutritionLabels nutritionLabels={content.nutritionLabels} />
    )}
  </>
)

SupplementPdpContent.propTypes = {
  content: PropTypes.object.isRequired,
}

export default SupplementPdpContent
